body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.navbar-left {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.logo {
  height: 40px;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-links-desktop {
  list-style: none;
  display: flex;
  gap: 50px;
}

.nav-links-desktop li a {
  text-decoration: none;
  color: #7a7a7a;
  transition: color 0.3s;
}

.nav-links-desktop li a:hover {
  color: #89a1ce;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: 20px;
  z-index: 1101;
  position: relative;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
}

.hamburger .close {
  font-size: 2em;
  color: #333;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1102;
}

.nav-links-mobile {
  list-style: none;
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding-top: 60px;
  transition: left 0.3s ease;
  z-index: 1001;
}

.nav-links-mobile.open {
  display: flex;
}

.nav-links-mobile li {
  margin: 20px;
  text-align: left;
  padding-left: 30px;
}

.nav-links-mobile li a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.nav-links-mobile li a:hover {
  color: #007BFF;
}

.hero-section {
  width: 100%;
  height: 90vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-text {
  color: #fff;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.about-section {
  padding: 80px 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.about-container {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.about-content-enhanced {
  padding: 20px;
  /* background: linear-gradient(to right, #ffffff, #e3e3e3); */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.about-image {
  max-width: 100%;
  border-radius: 10px;
}

.about-image-right {
  order: 2;
}

.services-section,
.about-section {
  background-color: #eaf1ff;
  padding: 60px 20px;
  text-align: center;
}
@media (min-width: 768px) {
#services > p{
  padding: 0.3rem 13rem;
}
}

.contact-section {
  background: linear-gradient(135deg, #eaf1ff 0%, #ffffff 100%);
  padding: 30px 20px;
  text-align: center;
}

.contact-section h2 {
  font-size: 2.5em;
  color: #2e3b55;
  margin-bottom: 10px;
}

.contact-section p {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 40px;
}

.contact-container {
  display: flex;
  flex-wrap: wrap; /* Añadido para permitir que los elementos se dispongan en varias filas en pantallas pequeñas */
  gap: 40px; /* Añadido para mantener un espaciado consistente entre los elementos */
  align-items: flex-start;
  justify-content: center;
}

.contact-info {
  flex: 1;
  max-width: 500px; /* Añadido para restringir el tamaño máximo */
  text-align: left;
}

.contact-detail {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}

.contact-detail svg {
  font-size: 2em; /* Aumenta el tamaño de los iconos */
  color: #2e3b55;
  margin-top: 5px;
}

.contact-detail p {
  margin: 0;
  font-size: 1.2em; /* Aumenta el tamaño del texto */
  color: #333;
  line-height: 1.5; /* Mejora la legibilidad con más espacio entre líneas */
}

.contact-detail a {
  font-size: 1.2em; /* Aumenta el tamaño del enlace */
  color: #007BFF;
  text-decoration: none;
}

.contact-detail a:hover {
  text-decoration: underline;
}

.contact-form {
  flex: 1;
  max-width: 500px; /* Añadido para restringir el tamaño máximo */
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.contact-form select:disabled {
  background-color: #f9f9f9; /* Color de fondo */
  color: #333; /* Color del texto */
  border: 1px solid #ddd; /* Borde */
  cursor: default; /* Sin cursor de edición */
  appearance: none; /* Elimina el estilo predeterminado */
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  width: calc(100% - 32px); /* Ajuste para que se mantengan dentro del contenedor */
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1em;
  color: #333;
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
}

.contact-form input:focus,
.contact-form select:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #007BFF;
}

.contact-form button {
  width: 100%;
  padding: 15px;
  background-color: #2e3c5c;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #89a1ce;
}

/* .about-content {
  margin-bottom: 20px;
} */

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  text-align: center;
}

.service img {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.cta-button {
  align-self: center;
  padding: 15px 30px;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.cta-button-primary {
  background-color: #28a745;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.cta-button-primary:hover {
  background-color: #218838;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.cta-button-appointment {
  background-color: #4285f4;
  color: #fff!important;
  border: none;
  padding: .8rem 2.2rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  transition: background-color 0.3s ease;
}

.cta-button-appointment:hover {
  background-color: #357ae8;
  color: #89a1ce!important;
}

.cta-button-appointment .fa-calendar-check {
  margin-right: 0.5rem;
}

.cta-button-secondary {
  background-color: #4285f4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cta-button-secondary:hover {
  background-color: #357ae8;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.cta-button-whatsapp {
  background-color: #25D366;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.cta-button-whatsapp:hover {
  background-color: #1DA851;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.footer {
  background-color: #f4f4f4; /* Color de fondo */
  color: #666; /* Color del texto */
  text-align: center;
  padding: 10px 0; /* Reduce el espacio vertical */
  border-top: 1px solid #ccc; /* Línea divisoria en la parte superior */
  font-size: 0.8em; /* Tamaño de fuente más pequeño */
}

.footer a {
  color: #666; /* Mismo color que el texto */
  text-decoration: none;
}

.footer a:visited {
  color: #666; /* Mismo color aunque esté visitado */
}

.footer a:hover {
  text-decoration: underline; /* Subrayado al pasar el ratón */
}

.wellness-section {
  padding: 80px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.wellness-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.wellness-text {
  flex: 1;
  max-width: 500px;
  text-align: center;
  line-height: 1.5;
}

.wellness-image {
  max-width: 400px;
  border-radius: 20px;
}

.recuperacion-section,
.centro-medicina-section {
  padding: 80px 20px;
  background-color: #e3f2fd;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.recuperacion-container,
.centro-medicina-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.recuperacion-text,
.centro-medicina-text {
  flex: 1;
  max-width: 500px;
  text-align: center;
}

.recuperacion-image,
.centro-medicina-image {
  max-width: 400px;
  border-radius: 20px;
}

.recuperacion-buttons {
  display: inline-flex;
  gap: 15px;
  margin-top: 20px;
}

.map-container {
  width: 100%;
  max-width: 100%;
  height: 500px; /* Altura predeterminada para pantallas grandes */
}

.footer-info {
  background-color: #f7f9fc;
  padding: 40px 20px;
  text-align: center;
}

.footer-info-container,
.footer-info {
  color: var(--e-global-color-text);
  font-family: "Roboto", Sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.footer-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.footer-logo,
.footer-contact,
.footer-social {
  flex: 1;
  max-width: 319px;
  color: #666;
  font-size: 0.9em;
  line-height: 1.6;
}

.footer-logo-image {
  max-width: 150px;
  margin-bottom: 10px;
}

.footer-logo p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #333;
  line-height: 1.6;
}

.cta-button-schedule {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #8eaee0;
  border-radius: 8px;
  text-decoration: none;
  margin-top: 10px;
  transition: box-shadow 0.3s ease; /* Añade una transición suave */
}

.cta-button-schedule:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Añade sombreado en hover */
}

.footer-contact h3,
.footer-social h3 {
  font-size: 1.1em; /* Mantén los encabezados un poco más grandes */
  color: #8eaee0;
  margin-bottom: 10px;
}

.footer-contact p,
.footer-social p {
  font-size: 0.9rem; /* Disminuye el tamaño de la fuente */
  color: #666;
  margin: 8px 0;
  line-height: 1.; /* Aumenta el interlineado */
}

.footer-contact p .fa-icon { /* Clase personalizada para agrandar los iconos */
  font-size: 1.5em; /* Aumenta el tamaño de los iconos */
  margin-right: 8px; /* Espacio a la derecha del icono */
  color: #8eaee0; /* Color de los iconos */
}

.footer-contact a {
  text-decoration: none; /* Quita el subrayado */
  color: #666; /* Igual color que el texto */
}

.footer-contact a:hover {
  color: #333; /* Color ligeramente más oscuro al pasar el ratón */
}

.social-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.social-icon {
  font-size: 2rem;
  color: #8eaee0;
  text-decoration: none;
  margin: 0 8px; /* Agrega un margen a la izquierda y derecha */
}

.social-icon:hover {
  color: #5b89d6;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: #2e3c5c;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;
}

.scroll-to-top:hover {
  background-color: #89a1ce;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 30px;
  background-color: #25D366;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.whatsapp-button2{
  cursor: pointer;
}

.whatsapp-button2:hover{
  text-shadow: #000000;
  color: #000;
}

.whatsapp-button:hover {
  background-color: #1DA851;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Estilos para el modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fondo oscuro */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  overflow: hidden; /* Evita que el contenido se desplace fuera del modal */
}

.modal-content {
  position: relative;
  background: #333;
  padding: 50px 0px;
  overflow-y: hidden;
  border-radius: 10px;
  max-width: 40%; /* Ajusta el ancho máximo para hacer el modal más pequeño */
  max-height: 70%; /* Ajusta la altura máxima para hacer el modal más pequeño */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: auto; /* Permitir scroll si el contenido es más grande */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-image.smaller {
  width: 80%; /* Reduce el tamaño de la imagen */
  height: auto;
  object-fit: contain; /* Ajusta la imagen para que se vea completa sin recortarse */
  border-radius: 10px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #fff; /* Cambia el color a blanco para que sea visible sobre el fondo oscuro */
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }
  .nav-links-desktop {
    display: none;
  }
  .navbar {
    justify-content: space-between;
  }
  .hero-section {
    height: 60vh;
  }
  /* .services {
    flex-direction: column;
  } */
  .about-container {
    flex-direction: column;
  }
  .about-image-right {
    order: 1;
  }
  .wellness-container {
    flex-direction: column;
  }
  .contact-container {
    flex-direction: column; /* Añadido para que los elementos se dispongan en columna en pantallas pequeñas */
    align-items: center; /* Añadido para centrar los elementos */
  }
  .contact-info,
  .contact-form {
    max-width: 100%; /* Añadido para que los elementos ocupen todo el ancho disponible */
  }
  .map-container {
    height: 300px; /* Ajusta la altura para pantallas pequeñas */
  }
  .footer-info-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer-logo,
  .footer-contact,
  .footer-social {
    max-width: 100%;
  }
  .responsive-image {
    max-width: 100%;
  }
  .modal-content {
    max-width: 70%; /* Agranda el modal en pantallas más grandes */
    max-height: 85%;
  }
  .modal-image.smaller {
    width: 95%; /* Incrementa el tamaño de la imagen en pantallas más grandes */
  }
}


/*NECESITO ARREGLARL LO RESPONSIVO ANTES DE CONTINUAR*/